body,
html {
  overflow-x: hidden;
  /* margin: 0 auto; */
}

h1,
h2,
h3,
h4,
h5,
label {
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

/* h3 {
  font-family: "Agency FB";
  font-weight: 100;
} */

a {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  color: #4d4d4d;
}
p {
  font-family: "Roboto Slab", serif;
  font-weight: 300;
  line-height: 2;
}

body {
  margin: 0 auto;
}

a:hover {
  color: #dc7883;
}

h1 {
  font-size: 4.3rem;
  color: #4d4d4d;
}

h4 {
  font-size: 1rem;
}

a {
  text-decoration: none;
}

button {
  background-color: transparent;
  border: none;
}

.animation {
  width: 100vw;
  height: 12vh;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 1.5rem;
  position: fixed;
  z-index: 1;
}

input,
textarea {
  width: 30vw;
  border-radius: 5px;
  font-family: monospace;
}

input {
  height: 2rem;
}

textarea {
  height: 5rem;
}

/* Media Queries */

@media screen and (max-width: 1300px) {
  .animation {
    height: auto;
  }
}

@media screen and (max-width: 1200px) {
  h1 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 1000px) {
  h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 900px) {
  h1 {
    font-size: 2.5rem;
  }
  .animation {
    justify-content: center;
    padding: 5.5rem 0 2rem 0;
    text-align: center;
  }

  input,
  textarea {
    width: 90vw;
  }
}
