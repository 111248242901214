.paper_container {
  padding-top: 3rem;
  width: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.contact_left_container,
.contact_right_container {
  width: 50vw;
  padding-top: 5rem;
}

.contact_right_container {
  background-color: rgba(220, 120, 131, 0.09);
}

.contact_left_container {
  padding: 2rem;
}

.contact_left_container p {
  font-size: 1.2rem;
}

.contact_animation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 11.7rem;
  padding: 3rem;
}

.contactForm_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: 2rem;
  margin: 2rem;
  padding: 1rem;
}

.contact_inputs {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.contact_button {
  width: 7rem;
  background-color: #b3005e;
  border: none;
  border-radius: 5px;
  color: #f0eeed;
  padding: 0.7rem;
  cursor: pointer;
  text-transform: uppercase;
  align-self: flex-end;
  margin: 2rem 5rem 0 0;
}

.contact_button:hover {
  background: rgb(255, 174, 174);
}

.contact_left_container img {
  width: 10vw;
  height: auto;
  position: relative;
  left: 70%;
  transform: rotate(-40deg);
}

/* Media Queries */

@media screen and (max-width: 1027px) {
  .paper_container {
    display: flex;
    flex-wrap: wrap;
  }

  .contact_left_container,
  .contact_right_container {
    width: 100vw;
    padding-top: 0;
    margin-top: 0;
  }

  .contact_right_container {
    padding: 2rem;
  }

  .contact_left_container img {
    width: v15w;
    position: relative;
    left: 45%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1) rotate(40deg);
  }
}

@media screen and (min-height: 704px) {
  .contact_left_container,
  .contact_right_container {
    height: 73vh;
  }
}
