.progressBar_container {
  height: 0.7rem;
  width: 100;
  background-color: #e0e0de;
  border-radius: 50px;
  margin: 2rem;
}

.filled_container {
  height: 100%;
  background-color: #707070;
  border-radius: inherit;
  text-align: right;
}

span {
  /* padding: 5px; */
  color: #ffffff;
  font-weight: bold;
}

/* ResumeProgressBar */

.resume_progressBar_container {
  height: 0.2rem;
  width: 70%;
  background-color: rgba(78, 110, 143, 66);
  border-radius: 50px;
  margin: 2rem 0 ;
  /* z-index: 3;
  position: absolute;
  left: 12rem; */
}

.resume_filled_container {
  height: 0.2rem;
  background-color: #ffffff;
  border-radius: inherit;
  text-align: right;
  
}

/* Media Queries */

@media screen and (max-width: 1000px) {
  .resume_progressBar_container {
    width: 90%;
    margin: 0;
  }
}
