.linkedin:hover {
  filter: invert(18%) sepia(96%) saturate(2504%) hue-rotate(183deg)
    brightness(114%) contrast(89%);
}

.github:hover {
  filter: invert(14%) sepia(4%) saturate(5%) hue-rotate(38deg) brightness(96%)
    contrast(82%);
}

.behance:hover {
  filter: invert(25%) sepia(93%) saturate(3748%) hue-rotate(211deg)
    brightness(103%) contrast(104%);
}

.instagram:hover {
    filter: invert(51%) sepia(57%) saturate(7455%) hue-rotate(305deg) brightness(80%) contrast(87%);
}

.resume:hover {
  filter: invert(45%) sepia(2%) saturate(4%) hue-rotate(12deg) brightness(96%)
    contrast(86%);
}

.button:hover {
  cursor: pointer;
}
