.skills_container {
  width: 30vw;
  height: auto;
  display: flex;
}

.skills_main {
  /* padding: 2rem 0.5rem; */
  display: flex;
  justify-content: center;
  max-width: 100%;
  background-image: url("../../Assets/skills_bg_keyboard_medium.jpg");
  background-repeat: no-repeat;
  background-position: top;
  height: auto;
  object-fit: cover;
  overflow-x: hidden;
}

.skills_main_box {
  height: 100vh;
  margin: 2rem;
}

.skills_main_tabs {
  padding-top: 6rem;
}

.skills_image {
  width: 7vw;
}

.skills_container .skills_image svg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.skills_progressBar {
  width: 100vw;
}

.skills {
  width: 70vw;
  height: auto;
  background: #f8f8f8;
  border: solid #bdbdbd 0;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 10px 26px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 10px 26px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 0 1rem;
}

.skills_name h2 {
  text-align: center;
  font-weight: 100;
}

.hidden {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.8s ease-in;
  background-color: #f8f8f8;
}

.skills_container {
  position: relative;
  width: 100%;
}

.skills_name {
  color: #707070;
  font-size: 1.3rem;
  position: absolute;
  top: 0%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.skills_container:hover .hidden {
  opacity: 1;
}

/* SkillsTabs CSS */

.tab_list {
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  border-bottom: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  height: auto;
  font-weight: thin;
  flex-direction: column;
}

.tab_list ul {
  border-bottom: none;
  display: flex;
  height: fit-content;
  width: 87vw;
  justify-content: space-evenly;
}

.tab {
  display: flex;
  border-bottom: none;
  padding: 0.5rem;
  cursor: pointer;
  margin-top: 1rem;
}

.skills_btn::selection {
  background: transparent;
}

.button_box {
  padding: inherit;
}

.button_box:hover {
  background-color: transparent;
}

.skills_btn {
  width: 18vw;
  background-color: #b3005e;
  border: none;
  border-radius: 5px;
  color: #f0eeed;
  padding: 0.7rem;
  cursor: pointer;
  text-transform: uppercase;
}

.skills_btn:hover {
  background: rgb(255, 174, 174);
}

/* Media Queries */

@media screen and (min-width: 1537px) {
  .skills_main_tabs {
    padding-top: 11rem;
  }
}

@media screen and (max-width: 1018px) {
  .skills_btn {
    width: 24.3vw;
  }

  .skills {
    width: 79vw;
  }
}

@media screen and (max-width: 1027px) {
  .tab {
    padding: 0;
  }

  .skills_main_tabs {
    padding-top: 8rem;
  }

  .skills_btn {
    width: 26vw;
  }

  .skills_main {
    padding: 0.5rem 0.5rem;
  }
}

@media screen and (min-height: 800px) {
  .tab_list {
    height: 100vh;
  }
}

@media screen and (max-width: 670px) {
  .skills_btn {
    font-size: 0.7rem;
  }

  .skills_main_tabs {
    padding-top: 8rem;
  }
}

@media screen and (max-width: 579px) {
  .skills_btn {
    width: 27vw;
    padding: 0.7rem 0.4rem;
  }
}

@media screen and (max-width: 521px) {
  .skills_btn {
    padding: 0.7rem 2rem;
    line-break: normal;
    text-align: center;
    width: 27vw;
  }
}

@media screen and (max-width: 463px) {
  .tab_list ul {
    display: flex;
    flex-direction: column;
  }

  .skills_btn {
    width: 85vw;
    align-items: center;
    justify-content: center;
  }

  .skills_main_tabs {
    padding-top: 10rem;
  }
}



