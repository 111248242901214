.resume {
  width: 100vw;
  height: auto;
  padding-top: 3.5rem;
  display: flex;
  overflow-x: hidden;
}

.main_resume_mobile {
  display: none;
}

.main_resume {
  display: flex;
}

.resume_left_container {
  width: 30%;
}

.resume_contact,
.profile_data {
  padding-top: 6rem;
}

.resume_left_container,
.resume_contact {
  height: auto;
  background-color: #153b61;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  font-weight: bold 700;
}

.resume_right_container {
  width: 66vw;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem 0 1rem;
}

.profile_picture {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  z-index: 1;
  padding: 3rem;
}

.profile_square {
  width: 70%;
  height: 2.5rem;
  background-color: rgba(78, 110, 143, 66);
  position: relative;
}

.profile_information {
  z-index: 1;
  line-height: 0;
  margin-top: -3.7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.profile_data_courses {
  display: inline-flex;
  align-content: center;
  width: 30rem;
  height: 8rem;
}

.profile_data {
  padding: 0;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
}

.profile_data p {
  white-space: pre-wrap;
  line-height: 1.5;
  margin-right: 2rem;
}

.profile_data hr {
  width: 27.5vw;
  background-color: rgba(78, 110, 143, 66);
  color: rgba(78, 110, 143, 66);
  margin: 0;
}

.profile_data_contact {
  display: flex;
  gap: 1rem;
  align-items: center;
  line-height: 0;
}

.profile_data_contact a {
  color: #ffffff;
}

.resume_skills_container {
  width: 10vw;
}

.skills_resume_left_container {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.resume_sections h3 {
  text-transform: uppercase;
}

.experience_container {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 0.5rem;
  margin-top: 1.5rem;
}

.resume_date {
  align-self: center;
}

.print {
  width: 10vw;
  height: 10rem;
  padding-top: 9rem;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.print span {
  color: #707070;
  font-size: 0;
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.print:hover span {
  font-size: 0.7rem;
  transition: width 4s linear 4s;
}

.print_text {
  color: white;
}

/* Media queries */

@media screen and (max-width: 1226px) {
  .information {
    white-space: pre-wrap;
    line-height: 1;
  }
}

@media screen and (max-width: 900px) {
  .main_resume_mobile {
    display: block;
    padding-top: 3rem;
  }

  .main_resume {
    display: none;
  }

  .resume_left_container,
  .resume_right_container {
    width: 100%;
    padding: 0;
  }

  .resume_contact,
  .profile_data {
    padding-top: 0;
    width: 100%;
  }

  .profile_data section,
  .profile_data p {
    padding: 0 1rem;
  }

  .profile_data h2,
  .profile_data hr {
    width: 90vw;
    display: flex;
    justify-content: center;
  }

  .resume_skills_container {
    width: 20vw;
  }

  .print span {
    color: #ffffff;
  }
}
