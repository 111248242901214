.navbar_container {
  width: 100vw;
  height: auto;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  background: #f8f8f8;
  border-bottom: solid #bdbdbd 0;
  box-shadow: 0 10px 26px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 10px 26px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 10px 26px rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 4;
  align-items: center;
}

.navbar_mobile ul,
.navbar ul {
  list-style-type: none;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0;
}

.logo {
  max-width: 25vw;
  height: auto;
  padding-left: 2rem;
  display: block;
}

button {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.navbar_mobile_container {
  display: none;
}

.navbar_mobile nav ul li {
  height: 5rem;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

li a:focus {
  color: #c92c6d;
}

.nav {
  background: #f8f8f8;
}
.nav nav {
  background: #f8f8f8;
}
.nav .linktext {
  font-size: 1.1em;
  text-align: center;
  position: relative;
  z-index: 2;
  text-decoration: none;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
}
.nav .linktext:before {
  display: inline-block;
  content: attr(data-text);
  position: absolute;
  top: 0px;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  opacity: 1;
  color: #ffabab;
  overflow: hidden;
  max-width: 0%;
  max-height: 0%;
}
.nav .item {
  position: relative;
  display: inline-block;
  padding: 0.5rem 0;
  margin-left: 25px;
  margin-right: 25px;
  background: transparent;
  z-index: 2;
  color: rgba(219, 68, 68, 0);
  color: #707070;
  text-decoration: none;
  font-family: Arial, sans-serif;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
}
.nav .item:hover .linktext:before {
  max-width: 100%;
  max-height: 100%;
}
.nav nav:hover .item {
  color: rgba(219, 68, 68, 0);
  text-shadow: 0px 0px 3px rgba(220, 120, 131, 0.7);
}
.nav nav .item:hover,
.navbar_mobile li:hover {
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
  color: rgba(219, 68, 68, 0);
  text-shadow: 0px 0px 0px rgba(255, 150, 150, 1);
}

/* Media queries */


@media screen and (max-width: 1027px) {
  .logo {
    max-width: 30vw;
  }
  .navbar_mobile_container {
    display: block;
  }

  .navbar {
    display: none;
  }

  .navbar_mobile {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    min-width: 100vw;
    align-self: center;
  }
}

@media screen and (max-width: 692px) {
  .logo {
    max-width: 25vw;
  }
}

@media screen and (max-width: 500px) {
  .logo {
    max-width: 30vw;
  }
}

@media screen and (max-width: 416px) {
  .logo {
    max-width: 35vw;
  }
}

@media screen and (max-width: 356px) {
  .logo {
    max-width: 40vw;
  }
}
