.about_container {
  width: 100vw;
  height: auto;
  display: flex;
  overflow-x: hidden;
  padding-top: 5rem;
}

.caroussel_box,
.about_text_box {
  width: 50vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.caroussel_box {
  margin-top: 2rem;
}

.about_text_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba(220, 120, 131, 0.09);
  color: #4d4d4d;
  padding: 0 2rem;
  margin-top: -1rem;
}

/* .about_text_box p {
  font-weight: 400;
  line-height: 2;
} */

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  transition: transform 0.2s;
  cursor: pointer;
  border-radius: 3px;
}

.grid_image_container {
  background: linear-gradient(to right, black 4px, transparent 4px) 0 0,
    linear-gradient(to right, black 4px, transparent 4px) 0 100%,
    linear-gradient(to left, black 4px, transparent 4px) 100% 0,
    linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
    linear-gradient(to top, black 4px, transparent 4px) 0 100%,
    linear-gradient(to top, black 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.middle {
  transition: 0.9s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.about_button:hover .image {
  opacity: 0.3;
}

.about_button:hover .middle {
  opacity: 1;
}

.text {
  color: #4d4d4d;
  font-size: 16px;
  padding: 16px 32px;
}

/* Media queries */

@media screen and (min-width: 1800px) {
  .about_container {
    padding-top: 7.3rem;
  }
}

@media screen and (max-width: 1200px) {
  .about_container {
    flex-wrap: wrap-reverse;
    padding-top: 3rem;
  }

  /* .about_container h1 {
    padding-top: 3rem;
  } */

  .about_text_box {
    padding: 2rem;
  }

  .caroussel_box,
  .about_text_box {
    width: 100vw;
  }
}

@media screen and (max-width: 800px) {
  .about_container {
    padding-top: 2rem;
  }
}
