.project_main_container,
.projects_container {
  width: 100vw;
  height: auto;
}

.project_transition_box {
  width: 100vw;
  height: 12rem;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: right;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.project_transition_box h1 {
  padding: 0 2rem;
}

.projects_container {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

}

.projects_props {
  width: 30vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 1rem 5rem;
}

.projects_left {
  border-right: #060047 2px solid;
}

.project_image_container_left img,
.project_image_container_right img {
  width: 30vw;
  height: auto;
  display: flex;
  cursor: pointer;
}

hr {
  width: 45vw;
  background-color: #060047;
  color: #060047;
}

.project_image_container_left hr {
  float: left;
  left: 5%;
}

.project_image_container_right hr {
  float: right;
  right: 5%;
}

.project_image_container_left hr,
.project_image_container_right hr {
  position: absolute;
}

.buttons_container {
  display: flex;
  justify-content: space-evenly;
  margin: 1rem 0;
}

.buttons_container button {
  width: 7rem;
  background-color: #b3005e;
  border: none;
  border-radius: 5px;
  color: #f0eeed;
  padding: 0.7rem;
  cursor: pointer;
  text-transform: uppercase;
}

.buttons_container button:hover {
  background: rgb(255, 174, 174);
}

img {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  z-index: -1;
  backface-visibility: hidden;
}

img:hover {
  opacity: 0.3;
}

.client_project_title {
  padding: 6rem 6rem 0 6rem;
}

.client_project_title h1 {
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
}

/* Media Queries */

@media screen and (max-width: 1200px) {
  .project_title,
  .client_project_title {
    padding-top: 6rem;
  }
}

@media screen and (max-width: 1000px) {
  .project_title {
    padding-top: 5rem;
  }

  .client_project_title {
    padding: 3rem 3rem 0 3rem;
  }
  .client_project_title h1 {
    font-size: 1.5rem;
  }
 
}

@media screen and (max-width: 800px) {
  .project_title,
  .client_project_title {
    padding-top: 4rem;
  }

  .buttons_container button {
    width: 6rem;
  }

  .buttons_container {
    gap: 5px;
  }
}

@media screen and (max-width: 618px) {
  .projects_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .projects_left {
    border-right: none;
  }

  .project_image_container_left,
  .project_image_container_right {
    border-bottom: #060047 2px solid;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  hr {
    display: none;
  }

  .project_transition_box {
    height: 5rem;
  }

  .projects_left,
  .projects_right {
    width: 60vw;
    padding: 2rem;
  }

  .buttons_container {
    display: flex;
    gap: 10px;
  }

  .projects_props {
    width: 60vw;
    padding: 2rem;
    display: flex;
    align-items: center;
  }

  .projects_props img {
    width: 60vw;
  }
}

@media screen and (max-width: 600px) {
  .project_title,
  .client_project_title {
    padding-top: 3rem;
  }

  
}
