.wrapper {
  /* height: 100vh; */
  display: grid;
  place-items: center;
}

.typing_demo {
  width: 36ch;
  animation: typing 2s steps(22), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 2em;
  margin-left: 2.5rem;
}

/* TextReveal animation CSS */

:root {
  --bg-color: #d8d8d8;
}

.text_reveal_container {
  width: 100%;
}

/* title styles */
.home_title span {
  position: relative;
  overflow-x:auto;
  display: block;
  line-height: 1.2;
  color: #4d4d4d;
}

.home_title span::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: rgb(255, 174, 174);
  animation: a-ltr-after 2s cubic-bezier(0.77, 0, 0.18, 1) forwards;
  transform: translateX(-101%);
}

.home_title span::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(220, 120, 131, 0.09);
  animation: a-ltr-before 2s cubic-bezier(0.77, 0, 0.18, 1) forwards;
  transform: translateX(0);
}

.home_title span:nth-of-type(1)::before,
.home_title span:nth-of-type(1)::after {
  animation-delay: 2s;
}

.home_title span:nth-of-type(2)::before,
.home_title span:nth-of-type(2)::after {
  animation-delay: 3s;
}

@keyframes a-ltr-after {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(101%);
  }
}

@keyframes a-ltr-before {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
  }
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

@media screen and (max-width: 800px) {
  .typing_demo {
    font-size: 1.5em;
    text-align: center;
    margin-left: 0;
  }
}

@media screen and (max-width: 450px) {
  .typing_demo {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 400px) {
  .typing_demo {
    font-size: 1em;
  }
}
